/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$primary: #3886c3;
$secondary: #ed1d91;
$info: #e5e7eb;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

:root {
  --fh-font-family: "Montserrat";
  --fh-primary-color: #3886c3;
  --fh-secondary-color: #ed1d91;
  --fh-info-color: #e5e7eb;
  --fh-grey-color: rgba(26, 26, 26, 0.6);
}

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";

//Public Sans
@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-ThinItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-ExtraLightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-SemiBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-ExtraBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "Public Sans";
  src: url("/assets/fonts/PublicSans-BlackItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
}

//Josefin Sans
@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-ThinItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-ExtraLightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-LightItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-MediumItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-SemiBoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Josefin Sans";
  src: url("/assets/fonts/JosefinSans-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}

* {
  font-family: "Josefin Sans";
}

body {
  margin: 0;
  font-size: 14px;
  line-height: 25px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.limit-content {
  max-width: 1280px;
  margin: auto;
  padding: 0 40px;
}

.submit-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.disabled,
.button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
